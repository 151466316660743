<template lang='pug'>
v-app
  v-navigation-drawer(permanent clipped app :width='300')
    v-btn#fullscreen-button(v-if='!fullscreen' @click='enableFullscreen' icon)
      v-icon mdi-fullscreen
    v-btn#fullscreen-button(v-else @click='disableFullscreen' icon)
      v-icon mdi-fullscreen-exit
    v-btn(v-if='admins && userId && adminsSplit.includes(userId)' @click='resetCache') Reset cache
    v-text-field.search(v-model='search' :label='t("searchText")' clearable append-icon='mdi-magnify')

    .loading(v-if='loading')
      v-progress-circular(indeterminate color='primary' :width='5' :size='50')
    v-list-item-group(v-model='filterIndexes' v-else multiple)
      v-list-item(value='Recently purchased')
        template(v-slot:default='{ active }')
          v-list-item-action
            v-checkbox(:input-value='active')
          v-list-item-content
            v-list-item-title {{ t('recentlyPurchasedText') }}
      v-divider
      v-list-item(v-if="this.search")
        v-list-item-title {{ t('searchCategoryText') }}
      v-list-item(v-for='category in filteredCategories' :key='category.ProductGroepOmschrijving' dense :value='category.ProductGroepOmschrijving')
        template(v-slot:default='{ active }')
          v-list-item-action.my-0
            v-checkbox(:input-value='active' dense)
          v-list-item-content.py-0
            v-list-item-title {{ category.ProductGroepOmschrijving }} 
              span.productCounter ({{category.length}})
      v-divider
      v-list-item(v-if="this.search") 
        v-list-item-title {{ t('searchProductText') }}
      v-list-item(v-for='category in filteredCategoriesByCategory' :key='category.ProductGroepOmschrijving + "x"' dense :value='category.ProductGroepOmschrijving')
        template(v-slot:default='{ active }')
          v-list-item-action.my-0
            v-checkbox(:input-value='active' dense)
          v-list-item-content.py-0
            v-list-item-title {{ category.ProductGroepOmschrijving }} 
              span.productCounter ({{category.length}})



  v-app-bar.d-flex.align-center(v-if='showAppBar' app color="primary" dark clipped-left)
      v-img.shrink.mr-2(alt="Simplex Logo" contain src="@/assets/favicon.png" transition="scale-transition" width="40")
      h1 {{ websiteName }}
  v-main(v-if='showStart')
    v-btn.to-shop(@click='showStart = false' color='primary') {{ t('toShopText') }}
      v-icon.ml-2 mdi-arrow-right
    .loading(v-if='!env')
      v-progress-circular(indeterminate color='primary' :width='5' :size='50')
    Simplex(v-else-if='env === "O87183AB"')
    DVH(v-else-if='env === "O75614AA"')
  v-main(v-else)
    .loading(v-if='loading')
      v-progress-circular(indeterminate color='primary' :width='7' :size='70')
    router-view(v-else)
    v-btn#cart-button(color="primary" dark absolute bottom right fab @click='cartOpen = !cartOpen' :class='{ animate: animateButton, offsetHeight: showAppBar, offsetWidth: fullscreen }')
      v-icon mdi-cart
      span {{ cartItems || '' }}
    v-expand-transition
      Cart#cart(v-show='cartOpen' @close='cartOpen = false' @confirm='createOrder')
  .overlay.creating-order(v-if='creatingOrder')
    v-progress-circular(indeterminate color='white' :width='7' :size='100')
    h1.my-5 {{ t('submittingOrderText') }}
  .overlay.order-done(v-else-if='orderDone && !orderError' @click='clickOrderDone()')
    v-icon(:size='100' color='primary' v-if="!orderError") mdi-check
    h1.my-5 {{ t('submitSuccesfulText') }}
    h2.my-5 {{ t('thankYouText') }}
  .overlay.order-done(v-else-if='orderDone && orderError' @click='clickOrderDone()')
    v-icon(:size='100' color='red') mdi-alert-outline
    h1.my-5 {{ t('submitUnSuccesfulText') }}
    h2.my-5 {{ orderError }}
    h2.my-5 {{ t('readErrorText') }}
</template>

<script>
import Cart from '@/components/Cart.vue';
import DVH from '@/components/start/DVH.vue';
import Simplex from '@/components/start/Simplex.vue';

export default {
  name: 'App',
  components: {
    Cart,
    DVH,
    Simplex
  },
  data () {
    return {
      cartOpen: false,
      filterIndexes: [],
      animateButton: false,
      search: null,
      loading: 0,
      creatingOrder: false,
      orderDone: false,
      searchTimeout: null,
      showStart: true
    }
  },
  watch: {
    filterIndexes(val) {
      this.showStart = false;
      this.$store.state.recentlyPurchasedFilter = val.includes('Recently purchased');
      this.$store.state.categoryFilters = val.filter((i) =>  i !== 'Recently purchased');
    },
    cart() {
      this.animateButton = true;
      setTimeout(() => this.animateButton = false, 500);
    },
    search(newVal) {
      this.showStart = false;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        this.$store.state.search = newVal;
      }, 500);
    }
  },
  computed: {
    categories() {
      const uniqueCategories = [];
      for (const p of this.$store.state.products) {
        const entry = uniqueCategories.find(c => c.ProductGroepOmschrijving === p.ProductGroepOmschrijving)
        if (!entry) {
          uniqueCategories.push({
            ProductGroepOmschrijving: p.ProductGroepOmschrijving,
            SortIndex: p.SortIndex,
            length: 1
          });
        } else {
          if (!entry.SortIndex) entry.SortIndex = p.SortIndex;
          entry.length++;
        }
      }
      return uniqueCategories;
    },
    filteredCategories() {
      return this.categories.filter(c => {
        if (!this.$store.state.search) return true;
        return c.ProductGroepOmschrijving.toLowerCase().includes(this.search.toLowerCase().trim());
      }).sort((a, b) => {
        const x = a["SortIndex"] ? a["SortIndex"] : 99999;
        const y = b["SortIndex"] ? b["SortIndex"] : 99999;
        return x - y || a["ProductGroepOmschrijving"].localeCompare(b["ProductGroepOmschrijving"]);
      });
    },
    filteredCategoriesByCategory() {
      if (!this.$store.state.search) {return []}
      else {
        const categories = this.$store.state.filteredProducts.map(a => a.ProductGroepOmschrijving);
        const uniqueCategories = [...new Set(categories)];

        return this.categories.filter(category => uniqueCategories.includes(category.ProductGroepOmschrijving));
      }
    },


    cart() {
      return this.$store.state.cart;
    },
    userId() {
      return this.$store.state.userId;
    }, 
    admins() {
      return this.$store.state.admins;
    },
    adminsSplit() {
      return this.$store.state.admins.split(';');
    },
    cartItems() {
      let counter = 0;
      for (const key of Object.keys(this.$store.state.cart)) {
        counter += this.$store.state.cart[key];
      }
      return counter;
    },
    showAppBar() {
      return this.$store.state.showAppBar || this.fullscreen;
    },
    fullscreen() {
      return this.$store.state.fullscreen;
    },
    env() {
      return this.$store.state.environment;
    },
    websiteName() {
      return this.$store.state.websiteName
    },
    orderError() {
      return this.$store.state.orderError
    }
  },
  mounted() {
    const [_, query] = window.location.href.split('#')[1].split('?');
    const { publickey } = Object.fromEntries(new URLSearchParams(query));

    if (!this.$store.state.products.length) {
      this.loading++;
      this.$store.dispatch('getProducts')
      .then(() => {
        if (localStorage.getItem(`cart_${publickey}`) && Object.keys(this.$store.state.cart).length === 0) {
          this.$store.state.cart = JSON.parse(localStorage.getItem(`cart_${publickey}`))
        }
      })
      .finally(() => {
        this.loading--;
      });
    }
    if (!this.$store.state.recentlyPurchased.length) {
      this.loading++;
      this.$store.dispatch('getRecentlyPurchased').finally(() => {
        this.loading--;
      });
    }
    
    const element = document.querySelector('#app');
    element.addEventListener('fullscreenchange', () => {
      if(document.fullscreenElement !== null){
        this.$store.state.fullscreen = true;
      } else {
        this.$store.state.fullscreen = false;
      }
    });

    this.$watch('cart', (newVal) => {
      localStorage.setItem(`cart_${publickey}`, JSON.stringify(newVal));
    }, { deep: true });
  },
  methods: {
    async resetCache() {
      await this.$store.dispatch('resetCache');
      this.loading++;
      this.$store.dispatch('getProducts')
      .then(() => {
        if (localStorage.getItem(`cart_${publickey}`) && Object.keys(this.$store.state.cart).length === 0) {
          this.$store.state.cart = JSON.parse(localStorage.getItem(`cart_${publickey}`))
        }
      })
      .finally(() => {
        this.loading--;
      });
    },
    countProductsInCategory(category) {
      let counter = 0;
      for (const p of this.$store.state.products) {
        if (p.ProductGroepOmschrijving === category) counter++;
      }
      return counter;
    },
    async createOrder(cart) {
      this.creatingOrder = true;
      try {
        await this.$store.dispatch('createOrder', cart);
        this.orderDone = true;
        this.cartOpen = false;
        if (!this.$store.state.orderError) {
          this.$store.state.cart = {};
        }
      } catch(e) {
        console.error(e);
      }
      this.creatingOrder = false;
    },
    async enableFullscreen() {
      const element = document.querySelector('#app');
      await element.requestFullscreen();
    },
    async disableFullscreen() {
      await document.exitFullscreen();
    },
    clickOrderDone() {
      this.orderDone = false;
      this.$store.dispatch('resetOrderError')
    }
  }
}
</script>

<style lang="scss" scoped>
#fullscreen-button {
  top: 10px;
  left: 10px;
  z-index: 100;
}
#cart-button {
  top: 16px;
  right: 34px;
  position: fixed;

  &.offsetWidth {
    right: 32px;
  }
}
.offsetHeight {
  top: 16px + 64px !important;
}
#cart {
  position: absolute;
  top: 16px + 56px + 16px;
  right: 16px;
}
#cart-button {
  span {
    position: absolute;
    font-size: 12px;
    top: -11px;
    right: 13px;
  }
}
.search {
  margin: 16px;
  margin-top: 12px;
  margin-bottom: 0;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.animate {
  animation: scale 0.5s ease;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.productCounter {
  color: grey;
}
.overlay {
  z-index: 1000;
  background-color: rgb(33, 33, 33, 0.75);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;

  &.order-done {
    cursor: pointer;

    h2 {
      max-width: 500px;
      text-align: center;
    }
  }
}
.to-shop {
  position: fixed;
  top: 25px;
  right: 25px;
}
</style>
